const defaultState = {
  userInfo: null,
  loginInfo: null,
};

const loginReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "userInfo":
      return { ...state, userInfo: action.data };
    case "loginInfo":
      return { ...state, loginInfo: action.loginInfo };
    default:
      return state;
  }
};

export default loginReducer;
