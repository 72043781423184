/**
 * HashRouter Hash模式
 * BrowserRouter History模式
 */
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import Loading from "../component/loading";

// function slowImport(value, ms = 1000){
//     return new Promise(resolve=>{
//         setTimeout(()=> resolve(value),ms);
//     })
// }
//  const Login = React.lazy(() => slowImport(import('../pages/login/index'), 1000000));

const Admin = React.lazy(() => import("../pages/admin/index"));
const Login = React.lazy(() => import("../pages/login/index"));
const Error = React.lazy(() => import("../pages/error/index"));
// 首页
const Welcome = React.lazy(() => import("../pages/admin/welcome/index"));
// 分类管理
const ClassificationList = React.lazy(() =>
  import("../pages/admin/classification/index")
);
// 商品管理
const CommodityNewUpdate = React.lazy(() =>
  import("../pages/admin/commodity/new-update/index")
);
const CommodityList = React.lazy(() =>
  import("../pages/admin/commodity/list/index")
);
// 订单管理
const Order = React.lazy(() => import("../pages/admin/order/index"));
// 用户管理
const UserManagement = React.lazy(() =>
  import("../pages/admin/userManagement/index")
);
// 统计分析
const StatisticalAnalysis = React.lazy(() =>
  import("../pages/admin/statisticalAnalysis/index")
);

// 页面重定向
function Redirect({ to }) {
  let navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
}

const BaseRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Redirect to="/admin" />}></Route>
      <Route
        path="/admin"
        element={
          <React.Suspense fallback={<Loading />}>
            <Admin />
          </React.Suspense>
        }
      >
        <Route
          index
          element={
            <React.Suspense fallback={<Loading />}>
              <Welcome />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="classList"
          element={
            <React.Suspense fallback={<Loading />}>
              <ClassificationList />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="commodityNewUpdate"
          element={
            <React.Suspense fallback={<Loading />}>
              <CommodityNewUpdate />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="commoditylist"
          element={
            <React.Suspense fallback={<Loading />}>
              <CommodityList />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="order"
          element={
            <React.Suspense fallback={<Loading />}>
              <Order />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="userManagement"
          element={
            <React.Suspense fallback={<Loading />}>
              <UserManagement />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="statisticalAnalysis"
          element={
            <React.Suspense fallback={<Loading />}>
              <StatisticalAnalysis />
            </React.Suspense>
          }
        ></Route>
      </Route>
      <Route
        path="login"
        element={
          <React.Suspense fallback={<Loading />}>
            <Login />
          </React.Suspense>
        }
      ></Route>
      <Route
        path="*"
        element={
          <React.Suspense fallback={<Loading />}>
            <Error />
          </React.Suspense>
        }
      ></Route>
    </Routes>
  </BrowserRouter>
);

export default BaseRouter;
