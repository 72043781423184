import { Spin } from "antd";
import "./index.less";

function Loading() {
  return (
    <div className="loading">
      <Spin tip="Loading..."></Spin>
    </div>
  );
}

export default Loading;
