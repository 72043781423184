import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/index.less";
import BaseRouter from "./router/index";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./store/store";
import unit from "./assets/tool/unit";

// 全局使用方法
React.Component.prototype.$unit = unit;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>{<BaseRouter />}</PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
